<template>
  <div v-if="homepageHeroWidgetData" class="hero hero--seasonal">
    <div class="hero__main">
      <div class="hero__copy">
        <div v-if="loggedIn" class="hero__welcome">
          Welcome back, {{ $auth.user.first_name }}
        </div>
        <h1>
          <template v-if="homepageHeroWidgetData.headline_link">
            <a :href="homepageHeroWidgetData.cta_link">{{
              homepageHeroWidgetData.headline
            }}</a>
          </template>
          <template v-else>
            <strong>{{ homepageHeroWidgetData.headline }}</strong>
          </template>
        </h1>
        <div v-html="homepageHeroWidgetData.copy" />
      </div>
      <div class="hero__cta">
        <a :href="homepageHeroWidgetData.cta_link" class="longarrow-black">
          {{ homepageHeroWidgetData.cta_text }}</a
        >
      </div>
    </div>
    <div class="hero__img">
      <img
        v-lazy-load :data-src="homepageHeroWidgetData.image_url"
        class="hero__img--desktop"
        alt=""
      />
      <img
        v-lazy-load :data-src="homepageHeroWidgetData.image_url"
        class="hero__img--mobile"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroSection',
  data() {
    return {
      homepageHeroWidgetData: null,
    }
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn
    },
  },
  watch: {
    loggedIn(newStatus) {
      if (newStatus) {
        this.fetchData()
      }
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const response = await this.$axios.get('/api/homepage/hero-section')
        this.homepageHeroWidgetData = response.data.homepage_hero_widget_data
      } catch (error) {
        console.error('Error fetching hero section data:', error)
      }
    },
  },
}
</script>
